.body-bg {
  background-size:cover;
  background-repeat:repeat-y;
  width:100%;
  height:100%;
  padding-top: 0px;
  margin-top: 110px;

  background-color:  black;
  /* background-color:  #8bc34a; */
  /* background-color: whitesmoke; */
  /* background-image: url('./imagenes/19-2.jpeg');  */
  background-position:top;
}

/* css para marcar item activo en el navbar */
a.active {
  /* background-color: darkgreen */
  /* text-decoration: underline;
  text-decoration-color: lightgreen; */
}

.estilo-footer {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.estilo-texto-fotter {
  padding: 10px
}


/* 
  Estilos para el parallax, basado en https://alligator.io/css/pure-css-parallax/  
*/

.wrapper {
  /* The height needs to be set to a fixed value for the effect to work.
   * 100vh is the full height of the viewport. */
  height: 75vh;
  /* The scaling of the images would add a horizontal scrollbar, so disable x overflow. */
  overflow-x: hidden;
  /* Enable scrolling on the page. */
  overflow-y: auto;
  /* Set the perspective to 2px. This is essentailly the simulated distance from the viewport to transformed objects.*/
  perspective: 2px;
}

.section {
  /* Needed for children to be absolutely positioned relative to the parent. */
  position: relative;
  /* The height of the container. Must be set, but it doesn't really matter what the value is. */
  height: 60vh;
  
  /* For text formatting. */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  color: whitesmoke;
  text-shadow: 0 0 10px #000;
}

.recuadro {
  background-color: #ff6f352e;
  /* height: 100vh; */
  display: flex;
  width: 800px;
  /* height: 100%; */
  position: absolute;
}

.parallax::after {
  /* Display and position the pseudo-element */
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  /* Move the pseudo-element back away from the camera,
   * then scale it back up to fill the viewport.
   * Because the pseudo-element is further away, it appears to move more slowly, like in real life. */
  transform: translateZ(-1px) scale(1.5);
  /* Force the background image to fill the whole element. */
  background-size: 100%;
  /* Keep the image from overlapping sibling elements. */ 
  z-index: -1;
}

/* The styling for the static div. */
.static {
  background: whitesmoke;
  /* height: 500px; */
}

/* Sets the actual background images  */

.bg1::after {
  background-image: url('/images/glp/Picture1.png');
  background-repeat: no-repeat;
  /* height: 100%; */ 
}


.bg10::after {
  background-image: url('/images/glp/Picture10.png');
  background-repeat: no-repeat;
  /* height: 100%; */ 
}

.bg42::after {
  background-image: url('/images/glp/Picture42.png');
  background-repeat: no-repeat;
  height: 100%; 
}


.bg51::after {
  background-image: url('/images/glp/Picture51.png');
  background-repeat: no-repeat;
  height: 100%; 
}

.bg52::after {
  background-image: url('/images/glp/Picture52.png');
  background-repeat: no-repeat;
  height: 100%; 
}

.bg53::after {
  background-image: url('/images/glp/Picture53.png');
  background-repeat: no-repeat;
  height: 100%; 
}

.bg54::after {
  background-image: url('/images/glp/Picture54.png');
  background-repeat: no-repeat;
  height: 100%; 
}

.bg55::after {
  background-image: url('/images/glp/Picture55.png');
  background-repeat: no-repeat;
  height: 100%; 
}

.bg56::after {
  background-image: url('/images/glp/Picture56.png');
  background-repeat: no-repeat;
  height: 100%; 
}

.bg58::after {
  background-image: url('/images/glp/Picture58.png');
  background-repeat: no-repeat;
  height: 100%; 
}